<template>
  <v-card
    :to="`/assets/collections/${publicID}`"
    class="mx-auto fill-height d-flex flex-column flex-wrap w-100"
    width="100%"
    rounded="xl"
    transition="fade-transition"
  >
    <v-img
      :src="banner"
      :height="imageHeight"
      max-width="100%"
      cover
    />

    <v-card-text class="mt-auto">
      <section class="d-flex align-center">
        <v-avatar size="40" class="mb-5">
          <v-img
            :src="logo"
          ></v-img>
        </v-avatar>

        <div class="fill-width overflow-hidden">
          <v-card-title class="pt-0 d-block text-truncate pr-0">{{ collectionName }}</v-card-title>
        </div>
      </section>
    </v-card-text>

<!--    <template v-if="showFavourites">-->
<!--      <v-divider />-->
<!--      <v-card-actions class="justify-end">-->
<!--        <v-btn icon color="red">-->
<!--          <v-icon>mdi-heart</v-icon>-->
<!--        </v-btn>-->
<!--      </v-card-actions>-->
<!--    </template>-->
  </v-card>
</template>

<script>
export default {
  name: 'CollectionItem',
  props: {
    publicID: {
      type: [Number, String],
      required: false,
      default: () => (false),
    },
    collectionName: {
      type: String,
      required: true,
      default: () => (''),
    },
    logo: {
      type: String,
      required: true,
      default: () => (''),
    },
    banner: {
      type: String,
      required: true,
      default: () => (''),
    },
    imageHeight: {
      type: [String, Number],
      required: false,
      default: () => (500),
    },
  },
  data: () => ({
    loading: false,
    selection: 1,
  }),
  methods: {
    reserve() {
      this.loading = true;

      setTimeout(() => { this.loading = false; }, 2000);
    },
  },
};
</script>

```
<usage>
<collection-card :
</usage>
